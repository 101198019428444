.list {
    padding-bottom: 30px;
    width: fit-content;
    justify-self: center;
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 0;
    animation: animatebottom 0.5s;
    animation-fill-mode: forwards;
}

.list>*:first-child {
    margin: 5px;
}

.weather-table-pair {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.weather-table-pair>* {
    min-width: max(300px, 50%);
    flex-grow: 1;
    padding: 5px;
}

@keyframes animatebottom {
    from {
        bottom: -30px;
        opacity: 0
    }
    to {
        bottom: 0;
        opacity: 1
    }
}