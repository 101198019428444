.weather_cell {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    white-space: nowrap;
    width: 65px;
    margin: 0;
    padding: 0;
    font-size: 11px;
    white-space: pre;
}

.weather_symbol {
    height: 40px;
    width: 40px;
    margin-top: -3px;
}

.minimum {
    color: #3c55a5 !important;
}

.dark .minimum {
    color: #92acff !important;
}

.maximum {
    color: #a53c3c !important;
}

.dark .maximum {
    color: #ff8383 !important;
}