.settings-page {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--backgroundColor2);
}

.settings-content {
    text-align: start;
    min-height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.settings-options-title {
    margin: 5px !important;
    margin-top: 20px !important;
    display: inline-block;
}

.settings-options {
    background-color: var(--backgroundColor1);
    width: 100%;
    padding: 10px;
}

.settings-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.settings-options .ant-space .ant-radio-wrapper,
.settings-options .ant-space {
    width: 100%;
}

#privacy-policy {
    align-self: center;
    margin: 10px;
    color: var(--secondaryTextColor) !important;
}