body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ant-typography {
    color: var(--primaryTextColor) !important;
}

.ant-typography-secondary {
    color: var(--secondaryTextColor) !important;
}

.ant-radio-wrapper,
.ant-divider,
.ant-btn,
.ant-input,
.ant-input-affix-wrapper {
    border-color: var(--dividerColor) !important;
    color: var(--primaryTextColor) !important;
}

div .ant-divider {
    margin: 0;
}

html {
    --backgroundGradient: linear-gradient(-20deg, #f5e3e3, #accdec);
    --backgroundColor1: #ffffff;
    --backgroundColor2: #fcfcfc;
    --primaryTextColor: #161616;
    --secondaryTextColor: #636363;
    --linkColor: #007aff;
    --dividerColor: #e7e7e7;
    --scrollbarBackground: var(--backgroundColor1);
    --scrollbarForeground: #e7e7e7;
    --scrollbarForegroundHover: #dddddd;
    --scrollbarForegroundActive: #d4d4d4;
}

html.dark {
    --backgroundGradient: linear-gradient(-20deg, #311314, #0f2132);
    --backgroundColor1: #3f3f3f;
    --backgroundColor2: #383838;
    --primaryTextColor: #d6d6d6;
    --secondaryTextColor: #8b8b8b;
    --linkColor: #2069b6;
    --dividerColor: #4e4e4e;
    --scrollbarBackground: var(--backgroundColor1);
    --scrollbarForeground: #575757;
    --scrollbarForegroundHover: #5f5f5f;
    --scrollbarForegroundActive: #666666;
}