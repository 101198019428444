/* Columns */

.columns {
    display: grid;
    grid-template-columns: 20px 1px auto;
    margin-bottom: 20px;
}

.all-columns {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 4;
}

.left-column {
    grid-row: 1;
    grid-column: 1;
    /* background-color: blue; */
}

.divider-column {
    grid-row: 1;
    grid-column: 2;
}

.right-column {
    grid-row: 1;
    grid-column: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
}


/* Rows */

.weather-row {
    margin-top: 10px;
    height: 100px;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.time-row {
    height: 20px;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


/* Logo */

.weather-provider-name {
    width: 80px;
    height: 20px;
    transform: rotate(-90deg);
}


/* Scroll bar */

.columns ::-webkit-scrollbar {
    height: 20px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: var(--scrollbarBackground);
    transform: translateY(20px);
}

.columns ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--scrollbarForeground);
}

.columns ::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background: var(--scrollbarForegroundHover);
}

.columns ::-webkit-scrollbar-thumb:active {
    border-radius: 10px;
    background: var(--scrollbarForegroundActive);
}