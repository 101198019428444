.location-list-item {
    width: 100%;
    margin: 0;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    border: none;
    background-color: transparent;
}

.location-list-item:hover {
    background-color: #00000008;
}

.location-list-item:active {
    background-color: #00000030;
}