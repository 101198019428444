.search {
    position: fixed;
    left: 0;
    top: 0;
    background-color: var(--backgroundColor2);
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: max-content max-content auto;
}

.search-bar {
    display: flex;
    flex-direction: row;
}

.location-search-spin {
    margin: 20px !important;
}

.search-cancel-button {
    border: transparent;
}