.weather-page {
    display: grid;
    /* Fix grid children overflowing outside the grid */
    grid-template-columns: minmax(0, 100%);
}

.title {
    margin-top: 50px;
}

.spin {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.settings-button,
.user-location-button,
.search-location-button {
    margin: 10px;
    background: transparent !important;
    border-color: #8b8b8b !important;
    color: #8b8b8b !important;
    border-color: var(--secondaryTextColor) !important;
    color: var(--secondaryTextColor) !important;
}

.search-location-button {
    margin-left: 0;
}