.header-container {
    background-color: var(--backgroundColor1);
}

.header-grid {
    display: grid;
    grid-template-columns: 25% 50% 25%;
}

.header-back-button {
    grid-column: 3;
    border-color: transparent !important;
    justify-self: end;
}

.header-back-button,
.header-back-button>span {
    color: var(--primaryTextColor) !important;
}

.header-title {
    grid-column: 2;
    margin: 0 !important;
    align-self: center;
}

.header-divider {
    margin: 0;
}